import { providers, Wallet } from 'ethers'

import { TApiCallData } from 'types/signedData'

const signDataForApi = async (
  action: string,
  chainId: number,
  collection: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  primaryData: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  details: string,
  signatureExpiry = 0
) => {
  if (!process.env.REACT_APP_SIGNER_RPC_URL) console.error('No REACT_APP_SIGNER_RPC_URL in .env.development file')
  if (!process.env.REACT_APP_SIGNER_PRIVATE_KEY) console.error('No REACT_APP_SIGNER_PRIVATE_KEY in .env.development file')

  const uiProvider = new providers.JsonRpcProvider(process.env.REACT_APP_SIGNER_RPC_URL)
  const uiPK = process.env.REACT_APP_SIGNER_PRIVATE_KEY
  const uiSigner = new Wallet(uiPK, uiProvider)

  const currentBlockNumber = await uiProvider.getBlockNumber()
  if (signatureExpiry === 0) signatureExpiry = currentBlockNumber + 300 - 1
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const message: any = [
    {
      name: 'blockchainList',
      version: '0.1',
      chainId: chainId,
      verifyingContract: '0x0000000000000000000000000000000000000000'
    },
    {
      validateAction: [
        {
          name: 'action',
          type: 'string'
        },
        {
          name: 'details',
          type: 'string'
        },
        {
          name: 'dbRef',
          type: 'string'
        },
        {
          name: 'signatureExpiry',
          type: 'uint256'
        }
      ]
    },
    {
      action,
      details,
      dbRef: JSON.stringify({
        collection,
        primaryData,
        data
      }),
      signatureExpiry
    }
  ]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const signature = await uiSigner._signTypedData(message[0], message[1], message[2])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fullData: TApiCallData = {
    collection,
    action,
    chainId,
    details,
    message,
    primaryData,
    data,
    signer: uiSigner.address,
    signature,
    signatureExpiry
  }
  return fullData
}

export default signDataForApi
