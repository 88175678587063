const addContent = (data) => {
    return fetch('/.netlify/functions/add-content', {
      body: JSON.stringify(data),
      method: 'POST'
    }).then((response) => {
      return response.json()
    })
}

const deleteContent = (data) => {
    return fetch('/.netlify/functions/delete-content', {
        body: JSON.stringify(data),
        method: 'POST'
    }).then((response) => {
        return response.json()
    })
}

const getContent = (data) => {
    return fetch('/.netlify/functions/get-content', {
        body: JSON.stringify(data),
        method: 'POST'
    }).then((response) => {
        return response.json()
    })
}

const updateContent = (data) => {
    return fetch('/.netlify/functions/update-content', {
        body: JSON.stringify(data),
        method: 'POST'
    }).then((response) => {
        return response.json()
    })
}

export { addContent, deleteContent, getContent, updateContent }  