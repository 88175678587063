import React, { lazy, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { useEffectOnce } from 'hooks/useEffectOnce'
import { signDataForApi } from './hooks/shared'
import { addContent } from './utils/content'

const Home = lazy(() => import('./views/Home'))
const Gallery = lazy(() => import('./views/Gallery'))
const GalleryList = lazy(() => import('./views/GalleryList'))

const NotFound = lazy(() => import('./views/NotFound'))

// eslint-disable-next-line
declare let window: any

const App: React.FC = () => {
  const [activityVisitsSaved, setActivityVisitsSaved] = useState(false)

  const handleSubmitActivityVisitsSaved = async () => {
    if (!activityVisitsSaved) {
      setActivityVisitsSaved(true)
      await addContent(
        await signDataForApi(
          'activityVisits',
          5,
          'activityVisits',
          {
            visitDate: new Date()
          },
          {
            visitDate: new Date(),
            visitUrl: window.location.href,
            visitReferrer: document.referrer,
            visitUserAgent: navigator.userAgent,
            visitScreenWidth: window.screen.width,
            visitScreenHeight: window.screen.height,
            visitDevicePixelRatio: window.devicePixelRatio,
            visitIsMobile: navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i) ? true : false,
            visitIsTablet: navigator.userAgent.match(/iPad|PlayBook|Tablet|Kindle|Silk/i) ? true : false,
            visitIsDesktop: navigator.userAgent.match(/Mac|PPC|Linux|Unix|Windows|WinNT|WinCE|Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)
              ? true
              : false,
            visitIsEthereum: window.ethereum ? true : false
          },
          'Save activityVisits'
        )
      )
    }
  }

  useEffectOnce(() => {
    handleSubmitActivityVisitsSaved()
  })

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/home" element={<Home />} />

        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:galleryId" element={<Gallery />} />
        <Route path="/gallery/:galleryId/spot/:nftUuId" element={<Gallery />} />

        <Route path="/galleryList" element={<GalleryList />} />

        <Route path="/gallery/:galleryId/:displayBox" element={<Gallery />} />
        <Route path="/gallery/:galleryId/:displayBox/:subSelection" element={<Gallery />} />
        <Route path="/gallery/:galleryId/:displayBox/:subSelection/:subFolder" element={<Gallery />} />
        <Route path="/gallery/:galleryId/:displayBox/:subSelection/:subFolder/:subFolderValue" element={<Gallery />} />

        {/* 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default React.memo(App)
